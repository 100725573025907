var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"post"}},[_c('h2',{attrs:{"id":"username"},on:{"click":function($event){return _vm.$router.push(("/profile/" + (_vm.post.username)))}}},[_vm._v(" "+_vm._s(_vm.post.username)+" ")]),_c('vueper-slides',{staticClass:"no-shadow",attrs:{"id":"image-slideshow","slide-ratio":1 / 4,"fixed-height":"500px"}},_vm._l((_vm.post.images),function(image){return _c('vueper-slide',{key:image.id,attrs:{"content":_vm.tagImage(image)}})}),1),_c('div',{attrs:{"id":"button-box"}},[(_vm.liked)?_c('button',{staticClass:"bluehover",attrs:{"id":"like"},on:{"click":function($event){{
          _vm.unlikePost();
        }}}},[_c('i',{staticClass:"fas fa-heart"})]):_c('button',{staticClass:"bluehover",attrs:{"id":"like"},on:{"click":function($event){{
          _vm.likePost();
        }}}},[_c('i',{staticClass:"far fa-heart"})]),_c('button',{staticClass:"bluehover",attrs:{"id":"comments-link"},on:{"click":function($event){return _vm.$router.push(("/postDetails/" + (_vm.post.postId)))}}},[_c('i',{staticClass:"far fa-comment"})])]),(_vm.favorited)?_c('button',{staticClass:"bluehover",attrs:{"id":"favorite-btn"},on:{"click":function($event){{
        _vm.unfavoritePost();
      }}}},[_c('i',{staticClass:"fas fa-bookmark"})]):_c('button',{staticClass:"bluehover",attrs:{"id":"favorite-btn"},on:{"click":function($event){{
        _vm.favoritePost();
      }}}},[_c('i',{staticClass:"far fa-bookmark"})]),_c('div',{attrs:{"id":"box-4-likes"}},[_c('p',[_vm._v(_vm._s(_vm.post.likeNumber)+" Likes")])]),_c('p',{attrs:{"id":"datetime"}},[_vm._v("POSTED "+_vm._s(_vm.timeSince)+" AGO")]),_c('div',{attrs:{"id":"caption"}},[_c('p',[_c('strong',[_vm._v(" "+_vm._s(_vm.post.username))]),_vm._v(" "+_vm._s(_vm.post.caption)+" ")])]),_c('comments',{attrs:{"id":"comments","postId":_vm.post.postId,"commentList":_vm.post.comments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }